import {
    bindable,
    bindingMode,
    customElement,
    inject,
    ObserverLocator,
} from 'aurelia-framework';
import { currencies } from '../currency/model/currencies.model';
import { UserClient } from '../api/user-client';
import { DialogService } from 'aurelia-dialog';
import { MultiPricesForm } from './multi-prices-form';

@customElement('sio-money-input')
@inject(ObserverLocator, UserClient, DialogService)
export class MoneyInput {
    @bindable config;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) value;
    @bindable({ defaultBindingMode: bindingMode.oneTime }) currency;

    viewChange = false;

    _value = {};

    currencyConfig = {
        set: 'currency',
        translateChoiceLabel: false,
        required: true,
    };
    field;

    constructor(observerLocator, userClient, dialogService) {
        this.observerLocator = observerLocator;
        this.userClient = userClient;
        this.dialogService = dialogService;
    }

    async bind() {
        this.field = this.config;
        this.defaultCurrency =
            this.currency ??
            this.userClient.user.settings.userSettings.currency;

        this.availableCurrencies = this.userClient?.user?.instance?.settings?.instanceGeneral?.currencyByLocales ?? [];
        this.valueChanged();
    }

    attached() {
        this.observerLocator
            .getObserver(this._value, 'amount')
            .subscribe(this._valueChanged.bind(this));
        this.observerLocator
            .getObserver(this._value, 'currency')
            .subscribe(this._valueChanged.bind(this));
    }

    valueChanged(newValue) {
        if (this.viewChange) {
            this.viewChange = false;
            return;
        }

        if (!this.value) {
            this._value.amount = null;
            this._value.currency = this.defaultCurrency;
            return;
        }

        //Note keep object reference here for observation
        this._value.amount = this._convertToView(
            this.value.amount,
            this.value.currency
        );
        this._value.currency = this.value.currency;
    }

    _valueChanged() {
        this.viewChange = true;

        if ((this._value.amount == null || this._value.amount === '')  && !this.field.options.multiPrice ) {
            this.value = null;
            return;
        }

        this.value = {
            amount: this._convertToModel(this._value.amount, this._value.currency),
            currency: this._value.currency,
        };
    }

    _convertToView(amount, currency) {
        let decimalDigits = currencies[currency]?.decimal_digits || 0;

        return amount / Math.pow(10, decimalDigits);
    }

    _convertToModel(amount, currency) {
        let decimalDigits = currencies[currency].decimal_digits;

        return Math.round(amount * Math.pow(10, decimalDigits));
    }

    addCurrencies() {
        this.dialogService
            .open({
                viewModel: MultiPricesForm,
                model: {
                    title: 'Preis in Währung',
                    currencies: this.value?.currencies?.length > 0  ? this.value?.currencies.map((curr) => {
                                return { amount: this._convertToView(curr.amount, curr.currency) , currency: curr.currency }
                    }): [],
                    currencyConfig: this.currencyConfig,
                    availableCurrencies: this.availableCurrencies,
                    defaultCurrency: this.defaultCurrency,
                },
                lock: false,
            })
            .whenClosed(async (response) => {
                if (!response.wasCancelled) {
                    if (this.value) {
                        this.value.currencies = response.output?.currencies?.map((curr) => {
                            return { amount:  this._convertToModel(curr.amount, curr.currency) , currency: curr.currency };
                        });
                    } else {
                        this.value = {};
                        this.value.currencies = response.output?.currencies?.map((curr) => {
                            return { amount:  this._convertToModel(curr.amount, curr.currency) , currency: curr.currency };
                        });
                    }
                }
            });
    }
}
