import {BindingEngine, customElement, inject, LogManager,} from "aurelia-framework";
import {Router} from "aurelia-router";
import {Client} from "../api/client";
import {UserClient} from "../api/user-client";
import {AuthTokenStorage} from "../auth/auth-token-storage";
import {AuthService} from "../auth/auth-service";
import {EventAggregator} from "aurelia-event-aggregator";
import {AureliaConfiguration} from "aurelia-configuration";
import {RouterHistory} from "../router/history";
import {ModuleConfigClient} from "../api/module-config-client";
import "./menu.less";
import $ from "jquery";
import "./jquery-menu-aim";
import {LocaleService} from "../i18n/locale-service";
import {render} from "react-dom";
import MenuPopover from "./menuPopover";
import moment from "moment-timezone";
import {PinService} from "../pin/pin";

const logger = LogManager.getLogger("menu");

@customElement("sio-menu")
@inject(
    Router,
    Client,
    ModuleConfigClient,
    AuthTokenStorage,
    AuthService,
    UserClient,
    EventAggregator,
    AureliaConfiguration,
    BindingEngine,
    LocaleService
)
export class Menu {
    mainMenu = null;

    constructor(
        router,
        client,
        moduleConfigClient,
        authTokenStorage,
        authService,
        userClient,
        ea,
        config,
        bindingEngine,
        localeService
    ) {
        this.router = router;
        this.client = client;
        this.moduleConfigClient = moduleConfigClient;
        this.authTokenStorage = authTokenStorage;
        this.authService = authService;
        this.userClient = userClient;
        this.ea = ea;
        this.config = config;
        this.bindingEngine = bindingEngine;
        this.localeService = localeService;
        this.container = $("#menuPopover");
        this.ispinned = PinService.isPinned();
    }

    attached() {
        console.debug("activate menu");

        this.showTodoItems();

        if (this.authTokenStorage.isLoggedIn()) {
            this.load();
        }
        this.subscription = this.ea.subscribe(
            "sio_login_change",
            (response) => {
                logger.debug("Login change", response);

                this.menu = null;
                this.user = null;

                if (response.loggedIn) {
                    this.load();
                }
            }
        );

        this.changeSubscription = this.ea.subscribe(
            "sio_form_post_submit",
            (response) => {
                this.showTodoItems();
                if (response.config.modelId != "user/user") {
                    return;
                }

                this.userClient.load().then((user) => {
                    this.user = user;
                });
            }
        );

        this.historySubscription = this.bindingEngine
            .propertyObserver(RouterHistory, "history")
            .subscribe(() => {
                this.history = RouterHistory.history;
            });

        this.isPinFeatureEnabled = PinService.isFeatureEnabled(this.user);

        if (this.isPinFeatureEnabled) {
            this.pinnedSubscription = this.bindingEngine
                .propertyObserver(PinService, "pinned")
                .subscribe(() => {
                    this.ispinned = PinService.isPinned();
                    this.pinned = PinService.pinned;
                });
        }

        this.history = RouterHistory.history;
        this.pinned = PinService.pinned;
    }



    detached() {
        this.subscription.dispose();
        this.changeSubscription.dispose();
        this.historySubscription.dispose();
        this.pinnedSubscription.dispose();
    }

    load() {
        this.client.get("menu").then((data) => {
            let user = _.remove(data, (n) => {
                return n.name === "user";
            });

            this.menu = {};

            data.forEach((item) => {
                if (item.items) {
                    let groups = {};
                    let noGroup = [];
                    let foundGroup = false;

                    item.items.forEach((item) => {
                        if (item.subLabel) {
                            groups[item.subLabel] = groups[item.subLabel] || [];
                            groups[item.subLabel].push(item);
                            foundGroup = true;
                        } else {
                            noGroup.push(item);
                        }
                    });

                    item.items = { noGroup: noGroup };

                    if (foundGroup) {
                        item.items.groups = groups;
                    }
                }
            });

            this.menu = data;


            console.debug("ROUTER", this.router);
        });

        this.user = this.userClient.user;

        this.moduleConfigClient
            .getView("base/user-menu-toolbar")
            .then((data) => {
                this.userToolbar = data;
            });
    }

    closeMenu(){
        $('#navbar').collapse("hide")
    }
    isActive(item, currentInstruction) {
        let currentParams = currentInstruction?.params;

        if (currentParams?.moduleId + "/" + currentParams?.viewId === item?.view) {
            return true;
        }

        if (item.items) {
            if (item.items.groups) {
                for (let group of Object.keys(item.items.groups)) {
                    for (let subItem of item.items.groups[group]) {
                        if (
                            currentParams?.moduleId +
                                "/" +
                                currentParams?.viewId ===
                            subItem.view
                        ) {
                            return true;
                        }
                    }
                }
            }

            for (let subItem of item.items.noGroup) {
                if (
                    currentParams?.moduleId + "/" + currentParams?.viewId ===
                    subItem.view
                ) {
                    return true;
                }
            }
        }

        return false;
    }

    imageUrl(file) {
        return file.previewUrl.replace("$width", "30").replace("$height", "-");
    }

    logout() {
        this.client.post('user/logout', {
            refresh_token: this.authTokenStorage.getRefreshToken(),
            access_token: this.authTokenStorage.getAccessToken(),
        }).then(() => {
            this.authService.logoutAfterError();
        }).catch(() => {
            this.authService.logoutAfterError();
        });
    }

    pinUnpin() {
        this.ispinned = PinService.addPinned();
    }

    isPinned() {
        return PinService.isPinned();
    }

    clearHistory() {
        RouterHistory.history = [];
    }

    showTodoItems() {
        const params = new URLSearchParams();
        params.set("offset", 0);
        params.set("sort%5B0%5D%5B%5D", "deadline");
        params.set("sort%5B0%5D%5B%5D", "DESC");
        params.set(
            "conditions",
            `{"$and":[{"done":{"$ne":true}},{"assigned":"@user"},[],{"deadline":{"$lte":"${moment().format("YYYY-MM-DDTHH:mmZ")}"}}]}`
        );

        this.client.get("todo/todo-entry?" + params.toString()).then((data) => {
            const container = document.getElementById("menuPopover");
            if (container) {
                render(<MenuPopover data={data}/>, container);
            }
        });
    }
}
