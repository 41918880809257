import React, { Fragment, useCallback, useEffect } from "react";
import {Panel, Button, Col, Whisper, Tooltip, Checkbox} from "rsuite";
import { formatPriceWithCommas } from "../helper/utility";
import StatsLabel from "../../../statistics/time-aggregation/stats-label";
import {useDispatch, useSelector} from "react-redux";
import {selectClearable, selectSelected, toggleSelect} from "../../../accounting/store/entries-slice";
import "./offer-preview-pricing-options.less";

export const OfferPreviewPricingOptions = ({
    selectedTime,
    dateHeader,
    timeInfos,
    isAccommodationTab,
    onSelectTime,
    preselectFirstEntry = false,
}) => {
    useEffect(() => {
        if (!preselectFirstEntry || selectedTime) {
            return;
        }

        const [enabledTimeInfo, enabledTime] = getFirstOpenHour(timeInfos);

        if (enabledTime) {
            onSelectTime(
                enabledTimeInfo,
                isAccommodationTab ? { date: timeInfo.date } : enabledTime
            );
        }
    }, [
        timeInfos,
        selectedTime,
        preselectFirstEntry,
        onSelectTime,
        isAccommodationTab,
    ]);

    function getFirstOpenHour(timeInfos) {
        let enabledTime = null
        let enabledTimeInfo = null

        timeInfos.forEach((info) => {
            for (const time of info.times) {
                if (time.disabled !== true) {
                    enabledTime = time;
                    enabledTimeInfo = info;

                    break;
                }
            }
        });

        return [enabledTimeInfo, enabledTime];
    }

    function getButton(time, idx, timeInfo) {
        return <Button
            className="preview-view-pricing-table-bottom-pannel-button"
            size="xs"
            color={
                selectedTime?.date === time.date
                    ? "green"
                    : null
            }
            key={idx}
            onClick={() => {
                onSelectTime(timeInfo, time);
            }}
            style={{margin: "5px"}}
            disabled={time.disabled}
        >
            <span className="preview-view-pricing-option__time">
                {time.hour}
            </span>
        </Button>;
    }

    function getWhisper(time, idx, timeInfo) {
        if (time.disabled) {
            return <Whisper
                trigger="hover" placement="bottomEnd" speaker={<Tooltip>{
                time.disabledReasons
                    .map(reason => <span>{reason}</span>)
                    .reduce((prev, curr) => [prev, ', ', curr])
            }</Tooltip>}>
                {getButton(time, idx, timeInfo)}
            </Whisper>;
        } else {
            return getButton(time, idx, timeInfo);
        }
    }

    const TimeAndPrices = timeInfos.map((timeInfo, index) => (
        <Fragment key={index}>
            <div className="preview-view-pricing-table__wrapper">
                <span className="preview-view-pricing-table__price">
                    {`${formatPriceWithCommas(
                        timeInfo.price,
                        timeInfo.currency
                    ) ?? 'Kein Preis'}`}
                </span>
                <span className="preview-view-pricing-table__time-buttons">
                    {timeInfo.times.map((time, idx) => getWhisper(time, idx, timeInfo))}
                </span>
            </div>
        </Fragment>
    ));
    const timeInfo = timeInfos[0]?.times?.[0];
    const formattedPrice = formatPriceWithCommas(
        timeInfos[0]?.price,
        timeInfos[0]?.currency
    );
    return (
        <Col className="preview-view-pricing-table-time-panel">
            <Panel className="preview-view-pricing-table-top-panel">
                {dateHeader}
            </Panel>
            {isAccommodationTab && (
                <Button
                    className="preview-view-pricing-table-top-panel preview-view-pricing-table-bottom-pannel-button"
                    block
                    color={
                        selectedTime?.date === timeInfo.date ? "green" : null
                    }
                    onClick={() => {
                        onSelectTime(timeInfos[0], { date: timeInfo.date });
                    }}
                >
                    <span>{formattedPrice} pro Person</span>
                </Button>
            )}
            {!isAccommodationTab && (
                <Panel
                    bordered
                    className="preview-view-pricing-table-bottom-pannel"
                >
                    {TimeAndPrices}
                </Panel>
            )}
        </Col>
    );
};
