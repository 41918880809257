import React, { useEffect, useState } from "react";
import moment from "moment";

const MenuPopover = ({ data }) => {
    const [menuData, setMenuData] = useState([]);
    const items = data.items || [];

    const groupBy = (xs, key) => {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    useEffect(() => {
        setMenuData(
            [...items].map((item) => {
                return {
                    name: item.name,
                    deadline: item.deadline,
                    id: item.id,
                };
            })
        );
    }, [data]);

    const groupedData = groupBy(menuData, "deadline") || [];
    return (
        <div
            style={{
                position: "fixed",
                width: "300px",
                height: "max-content",
                background: "white",
                top: "52px",
                right: "100px",
                padding: "15px",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                zIndex: "9999",
                color: "black",
                overflow: 'auto',
                maxHeight: '300px',
            }}
        >
            <p style={{ fontWeight: "bold" }}>Fällige Aufgaben:</p>
            {Object.keys(groupedData).length == 0
                ? "Keine demnächst fälligen Aufgaben"
                : Object.keys(groupedData).map((item) => {
                      return (
                          <div
                              key={item}
                              style={{
                                  paddingBottom: "2px",
                                  textAlign: "left",
                              }}
                          >
                              <span
                                  style={{
                                      backgroundColor: "#f3f3f3",
                                      width: "100%",
                                      display: "inline-block",
                                      padding: "2px 3px",
                                  }}
                              >
                                  {moment(item).format("DD.MM.YYYY")}
                              </span>
                              {groupedData[item].map((item) => {
                                  return (
                                      <a
                                          style={{
                                              fontSize: "14px",
                                              display: "block",
                                              cursor: "pointer",
                                          }}
                                          href={
                                              "/view/todo/todo-entry-view?id=" +
                                              item.id
                                          }
                                          key={item.id}
                                      >
                                          {item.name}
                                      </a>
                                  );
                              })}
                          </div>
                      );
                  })}
        </div>
    );
};

export default MenuPopover;
